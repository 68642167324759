
function WelcomeScreen({ onStart }) {
  return (
    <div className="h-screen flex flex-col items-center justify-center bg-primary text-base-100">
      <h1 className="font-title text-[clamp(2rem,6vw,4rem)] font-black tabular-nums">
        <span>Welcome to </span>
        <span className="animate-pulse text-accent">GLOW</span>
      </h1>
      <p className="text-xl mt-4">Empowering Your Journey to Wellness</p>

      <p className="text-base mt-4">We're working hard to bring you something amazing. Stay tuned!</p>
      {/* <button className="btn btn-accent mt-8" onClick={onStart}>
        Start Your Assessment
      </button> */}
    </div>
  );
}

export default WelcomeScreen;
