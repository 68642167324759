import "./App.css";

import { useState } from "react";
import WelcomeScreen from "./components/WelcomeScreen";
import HeightWeightInput from "./components/HeightWeightInput";
import BMIDisplay from "./components/BMIDisplay";

function App() {
  const [stage, setStage] = useState(0);
  const [userData, setUserData] = useState({});

  const handleStart = () => setStage(1);
  const handleInputSubmit = (data) => {
    setUserData(data);
    setStage(2);
  };

  return (
    <div >
      {stage === 0 && <WelcomeScreen onStart={handleStart} />}
      {stage === 1 && <HeightWeightInput onSubmit={handleInputSubmit} />}
      {stage === 2 && <BMIDisplay height={userData.height} weight={userData.weight} />}
    </div>
  );
}

export default App;
