// HeightWeightInput.js
import { useState } from "react";
import { LuRuler } from "react-icons/lu";
import { FiMonitor } from "react-icons/fi";
import { FiUser } from "react-icons/fi";

export default function HeightWeightInput({ onSubmit }) {
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("male");

  const handleSubmit = () => {
    onSubmit({ height, weight, age, gender });
  };

  return (
    <div className="h-screen flex items-center justify-center bg-gradient-to-br from-primary to-secondary text-neutral">
      <div className="card w-96 bg-base-100 shadow-xl p-6">
        <h2 className="text-2xl font-bold text-center mb-6">Enter Your Details</h2>

        <div className="form-control mb-4">
          <label className="label flex items-center space-x-2">
            <FiUser className="text-primary" size={24} />
            <span>Age</span>
          </label>
          <input type="number" className="input input-bordered w-full" placeholder="Enter age" value={age} onChange={(e) => setAge(e.target.value)} />
        </div>

        <div className="form-control mb-4">
          <label className="label flex items-center space-x-2">
            <LuRuler className="text-primary" size={24} />
            <span>Height (cm)</span>
          </label>
          <input type="number" className="input input-bordered w-full" placeholder="Enter height" value={height} onChange={(e) => setHeight(e.target.value)} />
        </div>

        <div className="form-control mb-4">
          <label className="label flex items-center space-x-2">
            <FiMonitor className="text-primary" size={24} />
            <span>Weight (kg)</span>
          </label>
          <input type="number" className="input input-bordered w-full" placeholder="Enter weight" value={weight} onChange={(e) => setWeight(e.target.value)} />
        </div>

        <div className="form-control mb-4">
          <label className="label flex items-center space-x-2">
            <span>Gender</span>
          </label>
          <select className="select select-bordered w-full" value={gender} onChange={(e) => setGender(e.target.value)}>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>
        </div>

        <button className="btn btn-accent w-full mt-4 hover:scale-105 transition transform duration-200" onClick={handleSubmit}>
          Calculate BMI
        </button>

        {/* Privacy Disclaimer */}
        <p className="text-sm text-center mt-4 text-gray-500">Note: We do not store your personal data. All information is for your assessment only.</p>
      </div>
    </div>
  );
}
