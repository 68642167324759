// LandingPage.js
import { useEffect, useState } from "react";

export default function LandingPage({ height, weight }) {
  const bmi = (weight / (height / 100) ** 2).toFixed(1);
  const recommendation =
    bmi < 18.5 ? "Underweight" : bmi < 24.9 ? "Normal weight" : bmi < 29.9 ? "Overweight" : "Obesity";

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress(bmi > 40 ? 100 : (bmi / 40) * 100);
  }, [bmi]);

  return (
    <div className="min-h-screen bg-gray-50 text-neutral">
      {/* Hero Section */}
      <header className="hero min-h-screen flex flex-col items-center justify-center text-center px-6 md:px-12 bg-gradient-to-br from-teal-600 to-blue-500 text-white">
        <h1 className="text-5xl font-bold mb-6">Your Personalized Path to Wellness</h1>
        <p className="text-lg md:text-xl max-w-3xl mb-12">
          Take charge of your health with expert guidance, tailored plans, and actionable insights.
        </p>

        {/* BMI Gauge */}
        <div className="relative flex items-center justify-center mb-6">
          <div
            className="radial-progress text-white"
            style={{
              "--value": progress,
              "--size": "12rem",
              "--thickness": "10px",
            }}
          >
            <span className="text-4xl font-semibold">{bmi}</span>
          </div>
        </div>

        <p className="text-xl font-medium">
          BMI Category: <span className="font-bold">{recommendation}</span>
        </p>
        <button className="btn btn-accent mt-8 hover:bg-accent-focus transition">Get Your Personalized Plan</button>
      </header>

      {/* Services Section */}
      <section className="py-16">
        <div className="container mx-auto px-6 md:px-12">
          <h2 className="text-4xl font-bold text-center mb-8 text-gray-800">Our Professional Services</h2>
          <p className="text-center text-lg text-gray-600 mb-12">
            We provide expert consultations and tailored plans to meet your health and lifestyle goals.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="bg-white shadow-lg p-6 rounded-lg text-center">
              <h3 className="text-2xl font-semibold text-teal-600 mb-4">Personalized Meal Plans</h3>
              <p className="text-gray-600">Custom meal plans designed for your unique nutritional needs.</p>
            </div>
            <div className="bg-white shadow-lg p-6 rounded-lg text-center">
              <h3 className="text-2xl font-semibold text-teal-600 mb-4">Weight Management</h3>
              <p className="text-gray-600">Achieve sustainable weight goals with professional guidance.</p>
            </div>
            <div className="bg-white shadow-lg p-6 rounded-lg text-center">
              <h3 className="text-2xl font-semibold text-teal-600 mb-4">Lifestyle Coaching</h3>
              <p className="text-gray-600">Build healthy habits with our comprehensive coaching programs.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="py-16 bg-teal-600 text-white">
        <div className="container mx-auto px-6 md:px-12">
          <h2 className="text-4xl font-bold text-center mb-8">Client Testimonials</h2>
          <p className="text-center text-lg mb-12 max-w-3xl mx-auto">
            Hear what our clients have to say about their transformative wellness journeys.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="bg-white text-gray-800 shadow-lg p-6 rounded-lg">
              <p className="italic">
                "The customized meal plans were perfect for my lifestyle, and I feel healthier than ever!"
              </p>
              <span className="block mt-4 font-medium text-teal-600">- Jessica M.</span>
            </div>
            <div className="bg-white text-gray-800 shadow-lg p-6 rounded-lg">
              <p className="italic">
                "With GLOW's weight management program, I achieved my goals effortlessly."
              </p>
              <span className="block mt-4 font-medium text-teal-600">- David P.</span>
            </div>
            <div className="bg-white text-gray-800 shadow-lg p-6 rounded-lg">
              <p className="italic">
                "Thanks to their guidance, I built sustainable habits that improved my overall health."
              </p>
              <span className="block mt-4 font-medium text-teal-600">- Maria R.</span>
            </div>
          </div>
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="py-16">
        <div className="container mx-auto px-6 md:px-12 text-center">
          <h2 className="text-4xl font-bold mb-8 text-gray-800">Start Your Journey Today</h2>
          <p className="text-lg text-gray-600 mb-8">
            Book a free consultation and take the first step toward your personalized wellness journey.
          </p>
          <button className="btn btn-primary px-8 py-4 text-lg font-medium hover:bg-primary-focus transition">
            Book Your Free Consultation
          </button>
        </div>
      </section>

      {/* Footer */}
      <footer className="py-8 bg-gray-800 text-gray-300 text-center">
        <p>© 2024 GLOW Nutrition Consulting. All rights reserved.</p>
        <p className="mt-2">
          <a href="#privacy-policy" className="text-teal-400 hover:text-teal-500">
            Privacy Policy
          </a>{" "}
          |{" "}
          <a href="#terms-conditions" className="text-teal-400 hover:text-teal-500">
            Terms & Conditions
          </a>
        </p>
      </footer>
    </div>
  );
}
